import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { ConfigService } from "./config.service";
import { AppConfig, UpgradeConfig } from "../models/app-config";
import { takeUntil } from "rxjs";

export interface PageUpgradeStatus {
  configured: boolean;
  isOverridden: boolean;
  user?: boolean;
}

class PageUpgrade {
  private configured = false;
  private user?: boolean;

  constructor(
    private readonly storageKey: string,
    private readonly configLookup: (
      config: UpgradeConfig,
    ) => boolean | undefined,
  ) {
    this.syncFromLocalStorage();
  }

  configure(config: AppConfig) {
    if (config.upgrade) {
      this.configured = this.configLookup(config.upgrade) ?? false;
    }
  }

  isEnvironmentConfigured() {
    return this.configured;
  }

  isUserOverridden() {
    return this.user !== undefined;
  }

  isUserConfigured() {
    return this.user ?? false;
  }

  isUpgraded() {
    return this.user ?? this.configured;
  }

  setUserOverride(upgraded: boolean) {
    this.user = upgraded;
    localStorage.setItem(this.storageKey, upgraded.toString());
  }

  clearUserOverride() {
    localStorage.removeItem(this.storageKey);
    this.user = undefined;
  }

  private syncFromLocalStorage() {
    const stored = localStorage.getItem(this.storageKey);
    if (stored === null) {
      this.user = undefined;
    } else {
      this.user = stored === "true";
    }
  }
}

export enum Page {
  ACCOUNT_INFORMATION,
  ACCOUNT_QUESTIONS,
  UMBRELLA_INFORMATION,
}

@Injectable({
  providedIn: "root",
})
export class PageUpgradeService implements OnDestroy {
  private destroyed$ = new EventEmitter();

  private readonly upgrades = new Map<Page, PageUpgrade>([
    [
      Page.ACCOUNT_INFORMATION,
      new PageUpgrade(
        "cq.config.upgrade.account-information",
        (upgrade) => upgrade.accountInformation,
      ),
    ],
    [
      Page.ACCOUNT_QUESTIONS,
      new PageUpgrade(
        "cq.config.upgrade.account-questions",
        (upgrade) => upgrade.accountQuestions,
      ),
    ],
    [
      Page.UMBRELLA_INFORMATION,
      new PageUpgrade(
        "cq.config.upgrade.umbrella-information",
        (upgrade) => upgrade.umbrellaInformation,
      ),
    ],
  ]);

  constructor(private readonly config: ConfigService) {
    this.config.app$.pipe(takeUntil(this.destroyed$)).subscribe((config) => {
      for (const page of this.upgrades.values()) {
        page.configure(config);
      }
    });
  }

  isUpgraded(page: Page) {
    const status = this.upgrades.get(page);
    return status?.isUpgraded() ?? false;
  }

  getStatus(page: Page): PageUpgradeStatus {
    const status = this.upgrades.get(page);
    return {
      configured: status?.isEnvironmentConfigured() ?? false,
      isOverridden: status?.isUserOverridden() ?? false,
      user: status?.isUserConfigured(),
    };
  }

  override(page: Page, upgraded: boolean) {
    const status = this.upgrades.get(page);
    status?.setUserOverride(upgraded);
  }

  reset(page: Page) {
    const status = this.upgrades.get(page);
    status?.clearUserOverride();
  }

  ngOnDestroy(): void {
    this.destroyed$.emit();
  }
}
