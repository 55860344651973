import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { SessionService } from "../../environment/services/session.service";
import { RequestService } from "../services/request.service";
import { MessageIdService } from "../services/message-id.service";
import { Requestor } from "../services/requestor";
import { TaskManagerService } from "@cq/app/diagnostic";
import { EdgeTask } from "../diagnostic/task";

//cspell:disable
const EDGE_ROOT_PATH =
  "policy-management/commercial-edge/v3/policy-center/service/edgevgpa6";
//cspell:enable

interface EdgeOptions {
  unlock?: boolean;
}

interface RequestorOptions {
  root: string;
  targeted: boolean;
  authentication?: "identity";
  capability?: string;
  handler?: string;
  method?: string;
  retry?: boolean;
  unlock?: boolean;
}

export class EdgeRequestor {
  constructor(
    private readonly manager: TaskManagerService,
    private readonly messageId: MessageIdService,
    private readonly session: SessionService,
    private readonly requestor: Requestor,
    private options: RequestorOptions,
  ) {}

  capability(capability: string) {
    this.options = { ...this.options, capability };
    return this;
  }

  handler(handler: string) {
    this.options = { ...this.options, handler };
    return this;
  }

  method(method: string) {
    this.options = { ...this.options, method };
    return this;
  }

  retry(retry = true) {
    this.options = { ...this.options, retry };
    return this;
  }

  unlock(unlock = true) {
    this.options = { ...this.options, unlock };
    return this;
  }

  jsonrpc<T, R>(data?: T) {
    if (
      !this.options.capability ||
      !this.options.handler ||
      !this.options.method
    ) {
      throw Error("Edge APIs require a capability, handler, and method");
    }

    const capability = this.options.capability;
    const handler = this.options.handler;
    const method = this.options.method;
    let headers = new HttpHeaders({
      "x-nw-source": this.session.id,
    });

    if (this.options.unlock) {
      headers = headers.set("x-nw-unlock", "true");
    }

    const messageId = this.messageId.generate();

    let requestor = this.requestor
      .authenticated(this.options.authentication)
      .headers(headers)
      .identified("legacy")
      .identifier(messageId)
      .targeted(this.options.targeted)
      .task(() => new EdgeTask(capability, handler, method, messageId));

    if (this.options.retry) {
      requestor = requestor.retry();
    }

    return requestor
      .jsonrpc()
      .post<R>(
        `${this.options.root}/${capability}/${handler}`,
        method,
        messageId,
        data,
      );
  }
}

@Injectable({
  providedIn: "root",
})
export class CommercialEdgeV3Service {
  protected readonly manager = inject(TaskManagerService);
  protected readonly messageId = inject(MessageIdService);
  protected readonly request = inject(RequestService);
  protected readonly session = inject(SessionService);

  builder() {
    return new EdgeRequestor(
      this.manager,
      this.messageId,
      this.session,
      this.request.apigee(),
      {
        root: EDGE_ROOT_PATH,
        targeted: true,
      },
    );
  }

  jsonrpc<T, R>(
    capability: string,
    handler: string,
    method: string,
    data?: T,
    options?: EdgeOptions,
  ) {
    let request = this.builder()
      .capability(capability)
      .handler(handler)
      .method(method);

    if (options?.unlock) {
      request = request.unlock();
    }

    return request.jsonrpc<T, R>(data);
  }
}
