import { Injectable, computed, signal } from "@angular/core";
import { PolicyTransaction } from "../models/policy";

@Injectable({
  providedIn: "root",
})
export class PolicyTransactionStoreService {
  private readonly _transactions = signal<PolicyTransaction[]>([]);
  public readonly transactions = this._transactions.asReadonly();
  public readonly hasTransactions = computed(
    () => this.transactions().length > 0,
  );

  private readonly _active = signal<PolicyTransaction | undefined>(undefined);
  readonly active = this._active.asReadonly();

  private readonly _activePolicyTransactionId = signal<string | undefined>(
    undefined,
  );
  readonly activePolicyTransactionId = computed(() => {
    const activeTransaction = this.active();
    const activePolicyTransactionId = this._activePolicyTransactionId();
    return activeTransaction?.id ?? activePolicyTransactionId;
  });

  // Policy products added to account
  public readonly products = computed(() =>
    this.transactions().map((transaction) => transaction.product),
  );

  setPolicyTransactions(transactions: PolicyTransaction[]) {
    this._transactions.set(transactions);
  }

  addPolicyTransactions(transactions: PolicyTransaction[]) {
    this._transactions.update((current) => {
      const updated = [...current];
      updated.push(...transactions);
      return updated;
    });
  }

  // store the transaction as the currently active policy transaction
  activate(transaction: PolicyTransaction) {
    this._active.set(transaction);
  }

  // remove the transaction as the currently active policy transaction
  deactivate(transaction: PolicyTransaction) {
    const current = this._active();
    if (current && current.id === transaction.id) {
      this._active.set(undefined);
    }
  }

  // set the active policy transaction id from legacy
  setActivePolicyTransactionId(policyTransactionId: string) {
    this._activePolicyTransactionId.set(policyTransactionId);
  }

  // clear the legacy active policy transaction id
  clearActivePolicyTransactionId(policyTransactionId: string) {
    const current = this._activePolicyTransactionId();
    if (current && current === policyTransactionId) {
      this._activePolicyTransactionId.set(undefined);
    }
  }
}
