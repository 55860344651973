import _ from "underscore";
import ModalService from "@clearquote/common/modal/modal.service";
import QuoteService from "@clearquote/common/QuoteService";
import LaunchService from "@clearquote/common/launch.service";
import JobManagement from "@clearquote/common/navigation/job-management.service";
import UserDTO from "@clearquote/generated/dto/edge/capabilities/user/dto/user-dto";
import JobSummaryDTO from "@clearquote/generated/dto/edge/capabilities/gateway/job/dto/job-summary-dto";
import AccountResDTO from "@clearquote/generated/dto/com/nw/cl/edge/capabilities/aeportal/account/dto/account-res-dto";
import { ReviewNavigationService } from "../common/navigation/review-navigation.service";
import AccountService from "../common/account.service";
import QuoteDataDTO from "@legacy/nationwide/generated/dto/edge/capabilities/quote/submission/dto/quote-data-dto";
import ProgressService from "@clearquote/common/progress.service";
import { AccountNavigationRouterService } from "@cq/app/account/navigation/account-navigation-router.service";
import UserService from "../common/user.service";
import JobOrchestrationService from "../common/navigation/job-orchestration.service";

const ACCOUNT_STATUSES = [
  "DeclinedFinancial",
  "DeclinedUnderwriting",
  "non_renewed",
  "cancelled",
  "DeclineClassOfBusiness",
  "cancelled_insured_request",
  "DeclinedFinancialAndUnderwriting",
  "PendingCancellation",
  "PendingNonRenewal",
];

export class LegacyAccountRetrieveService {
  /* @ngInject */
  constructor(
    private readonly AEAccountService: AccountService,
    private readonly AEQuoteService: QuoteService,
    private readonly AELaunchService: LaunchService,
    private readonly AEJobManagementService: JobManagement,
    private readonly AEJobOrchestrationService: JobOrchestrationService,
    private readonly AEProgressService: ProgressService,
    private readonly CQModalService: ModalService,
    private readonly CQAccountNavigationRouter: AccountNavigationRouterService,
    private readonly CQReviewNavigation: ReviewNavigationService,
    private readonly AEUserService: UserService,
  ) {}

  routeToAccount(
    accountNumber: string,
    user: UserDTO | undefined = this.AEUserService.current,
  ) {
    return this.AEProgressService.resolve(
      Promise.all([
        this.AEAccountService.retrieve(accountNumber),
        this.AEAccountService.getJobSummary(accountNumber),
      ]).then(([accountData, jobSummary]) => {
        this.route(accountData, jobSummary, user, true);
        this.AEJobOrchestrationService.loadExposures();
      }),
    );
  }

  route(
    accountData: AccountResDTO,
    jobSummary: JobSummaryDTO[],
    user?: UserDTO,
    reload = false,
  ) {
    const accountReq = accountData.accountReq;
    const accountNumber = accountReq?.account_Ext?.accountNumber as string;
    const quoteStatus: any[] = [];
    const isUserExternal = user?.isUserExternal ?? true;

    //Market Segment: Middle Market
    if (accountReq?.marketSegment === "middle_commercial") {
      this.AELaunchService.policyCenter.accountFileSummary(accountNumber);
      return new Promise<void>(() => {});
    }

    //Market Segment: Small Market - Agent Type: External - Practice Area: Human Services
    if (
      accountReq?.marketSegment === "small_commercial" &&
      isUserExternal &&
      accountReq.selectedPracticeArea_Ext === "human_services"
    ) {
      this.AELaunchService.policyCenter.accountFileSummary(accountNumber);
      return new Promise<void>(() => {});
    }

    //Market Segment: Small Market - Agent Type: Internal - Practice Area: Human Services or Senior Living
    if (
      accountReq?.marketSegment === "small_commercial" &&
      !isUserExternal &&
      (accountReq.selectedPracticeArea_Ext === "human_services" ||
        accountReq.selectedPracticeArea_Ext === "senior_living")
    ) {
      this.AELaunchService.policyCenter.accountFileSummary(accountNumber);
      return new Promise<void>(() => {});
    }

    if (
      _.contains(ACCOUNT_STATUSES, accountReq?.accountStatus) ||
      jobSummary?.every((item) => item.status === "Declined")
    ) {
      this.AELaunchService.policyCenter.accountFileSummary(accountNumber);
      return new Promise<void>(() => {});
    }

    /**
     * 555412:Common: Retrieve Quote in CQ
     * Point 3 -> a .
     * Retrieve in Clear Quote if Account Status = Acceptable OR Not Taken
     */
    if (accountReq?.accountStatus === "Acceptable") {
      _.forEach(
        this.AEJobManagementService.getAllJobSummaryDetails(),
        (summ) => {
          quoteStatus.push(summ.status);
        },
      );
      if (this.AEJobManagementService.hasDraftRenewals()) {
        this.CQModalService.error(
          "nationwide.services.exceptionHandler.accountInDraftRenewal",
        );
        return Promise.reject();
      } else {
        // Prompt for copy submission if available
        this.AEAccountService.checkForCopySubmission(
          accountNumber,
          accountData,
        );
        return this.routeByStatus(accountNumber, quoteStatus, reload);
      }
    }

    return new Promise<void>(() => {});
  }

  private routeByStatus(
    accountNumber: string,
    quoteStatus: string[],
    reload: boolean,
  ) {
    const hasAllDeclinedPolicies =
      quoteStatus.length > 0 && !quoteStatus.some((x) => x !== "Declined");

    /**
     * If there are all the policies declined then go to the PC screen. FIRST PRIORITY
     */
    if (hasAllDeclinedPolicies) {
      this.AELaunchService.policyCenter.accountFileSummary(accountNumber);
      return new Promise<void>(() => {});
    }

    return this.routeInSystem(accountNumber, quoteStatus, reload);
  }

  private routeInSystem(
    accountNumber: string,
    quoteStatus: string[],
    reload: boolean,
  ) {
    /* IF ATLEAST ONE QUOTED LAND TO RQ SCREEN - FIRST PRIORITY */
    if (
      _.contains(quoteStatus, "Quoted") ||
      _.contains(quoteStatus, "Quoting")
    ) {
      return this.CQReviewNavigation.navigateToReviewAndQuote(accountNumber, {
        reload,
        allowDeactivation: true,
      });
    } else if (_.contains(quoteStatus, "Draft")) {
      return this.checkUWIssues(accountNumber, reload);
    } else if (this.AEJobManagementService.hasBoundPolicies()) {
      return this.CQAccountNavigationRouter.navigateToAddProducts(
        accountNumber,
        {
          reload,
          allowDeactivation: true,
        },
      );
    } else {
      return this.CQAccountNavigationRouter.navigateToAccountInformation(
        accountNumber,
        {
          reload,
          allowDeactivation: true,
        },
      );
    }
  }

  private checkUWIssues(accountNumber: string, reload: boolean) {
    const multipleRetrieveQuote: Promise<QuoteDataDTO>[] = [];
    _.forEach(this.AEJobManagementService.getJobNumbers(), (item) => {
      multipleRetrieveQuote.push(
        this.AEQuoteService.retrieveQuote({ quoteID: item }),
      );
    });
    const navigate = Promise.all(multipleRetrieveQuote).then((resp: any) => {
      let uwIssuesExist = false;
      _.forEach(resp, (data) => {
        if (data.status === "Draft" && data.uWIssues.length > 0) {
          uwIssuesExist = true;
        }
      });
      // IF HAS DRAFT POLICIES AND UW ISSUES - LAND TO RQ SCREEN - FIRST PRIORITY
      if (uwIssuesExist) {
        return this.CQReviewNavigation.navigateToReviewAndQuote(accountNumber, {
          reload,
          allowDeactivation: true,
        });
      } else if (this.AEJobManagementService.hasBoundPolicies()) {
        return this.CQAccountNavigationRouter.navigateToAddProducts(
          accountNumber,
          {
            reload,
            allowDeactivation: true,
          },
        );
      } else {
        return this.CQAccountNavigationRouter.navigateToAccountInformation(
          accountNumber,
          {
            reload,
            allowDeactivation: true,
          },
        );
      }
    });
    return this.AEProgressService.resolve(navigate).result;
  }
}
